import Vue from 'vue';

import Page from './page/main.vue';

import {i18n} from '/public/i18n';

new Vue({
    el: "#app",
    render: h => h(Page),
    i18n,
});
