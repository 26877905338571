<template>
  <div class="iframe-order-wrapper">
    <div 
      v-if="isError" 
      class="error"
    >
      {{ error }}
    </div>

    <template v-else-if="isPending">
      <spinner style="margin: auto" />
    </template>

    <div
      v-else-if="!isOrganizationDefined"
      class="wrong-statuses"
    >
      {{ $t('iframe-order.info.status.wrong-organization-id') }}
    </div>
    <div
      v-else-if="!isOrderDefined"
      class="wrong-statuses"
    >
      {{ $t('iframe-order.info.status.wrong-order-id') }}
    </div>

    <template v-else-if="!isEmailEntered">
      <main-header
        v-bind="baseInfo"
        :is-apollo="isApollo"
        :order-id="orderId"
      />
      <email-body
        v-bind="baseInfo"
        :email-valid="emailValid"
        :clientEmail.sync="clientEmail"
      />

      <div>
        <email-footer
          :order-amount="orderAmount"
          :currency="currency"
          :is-apollo="isApollo"
        >
          <template #action>
            <base-button
              :label="$t('iframe-order.btn.continue')"
              :disabled="!emailValid || !canMakePayment"
              @click="onSubmit"
            />
          </template>
        </email-footer>
      </div>
    </template>

    <template v-else-if="hasOrder">
      <main-header
        v-bind="baseInfo"
        :is-apollo="isApollo"
        :order-id="orderId"
      />
      <order-body
        v-bind="{...baseInfo, ...orderInfo}"
        :timeIsOver="timeIsOver"
      />
      <remaining-time-footer
        v-if="!isStatusSuccess"
        v-bind="orderInfo"
        @timeIsOver="timeIsOver = true"
        style="margin: 0 -20px"
      />
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { i18n } from "@/public/i18n";

export default {
  name: "Main",
  data() {
    return {
      theme: 'light',
      lang: 'en',

      apiKey: null,

      organizationId: null,
      orderId: null,
      description: null,
      currency: null,
      network: null,
      amount: null,

      pending: false,
      error: null,
      paymentMethod: null,
      paymentEmail: null,

      clientEmail: '',

      baseInfo: null,
      orderInfo: null,
      query: '',
      timeIsOver: false
    };
  },
  components: {
    Spinner: () => import(/* webpackChunkName: "entries/iframe-order/components/Spinner" */ '@/entries/iframe-order/components/Spinner'),
    MainHeader: () => import(/* webpackChunkName: "entries/iframe-order/components/Header" */ '@/entries/iframe-order/components/Header'),
    EmailBody: () => import(/* webpackChunkName: "entries/iframe-order/components/Body/Email" */ '@/entries/iframe-order/components/Body/Email'),
    OrderBody: () => import(/* webpackChunkName: "entries/iframe-order/components/Body/Order" */ '@/entries/iframe-order/components/Body/Order'),
    EmailFooter: () => import(/* webpackChunkName: "entries/iframe-order/components/Footer/Email" */ '@/entries/iframe-order/components/Footer/Email'),
    BaseButton: () => import(/* webpackChunkName: "entries/iframe-order/components/Button" */ '@/entries/iframe-order/components/Button'),
    RemainingTimeFooter: () => import(/* webpackChunkName: "entries/iframe-order/components/Footer/RemainingTime" */ '@/entries/iframe-order/components/Footer/RemainingTime')
  },
  computed: {
    isApollo() {
      const { host } = location
      return host.includes('apollo')
    },
    isOrganizationDefined() {
      return !!this.organizationId;
    },
    isOrderDefined() {
      return !!this.orderId;
    },
    isPending() {
      if(!this.isOrganizationDefined || !this.isOrderDefined) {
        return false;
      }

      if(this.isError) {
        return false;
      }

      return this.pending;
    },
    isError() {
      return !!this.error;
    },
    isMethodSelected() {
      return !!this.paymentMethod;
    },
    isEmailEntered() {
      return !!this.paymentEmail;
    },
    hasOrder() {
      return !!this.orderInfo;
    },
    orderAmount() {
      return this.query.get('amount')
    },
    emailValid({ clientEmail }) {
      const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return regExp.test(clientEmail)
    },
    canMakePayment({ baseInfo }) {
      return baseInfo.canPayment
    },
    isStatusSuccess({ orderInfo }) {
      return orderInfo.status === 'overpaid' || orderInfo.status === 'processed'
    }
  },
  mounted() {
    this.parseUrl();
    this.replaceUrl();

    this.loadBaseInfo();
  },
  methods: {
    parseUrl() {
      // http(s)://.../:organizationId?orderId=...&description=...&currency=...&network=...&amount

      let {pathname: organizationId, search} = window.location;

      organizationId = (window.location.pathname.split('?')[0] + '').substring(1);
      search = (search + '').substring(1);

      this.query = new URLSearchParams(search);

      let
        apiKey = this.query.get('apiKey') || '',
        theme = this.query.get('theme') || '',
        lang = this.query.get('lang') || '',
        orderId = this.query.get('orderId') || '',
        description = this.query.get('description') || '',
        currency = this.query.get('currency') || '',
        network = this.query.get('network') || '',
        amount = this.query.get('amount') || '',
        payerEmail = this.query.get('email') || '';

      if(organizationId.length === 36) {
        this.organizationId = organizationId;
      }

      //на данный момент тема всего одна - темная
      if ( theme === 'dark' ) {
        this.theme = 'dark'
      } else {
        this.theme = 'light'
      }

      console.log('this.theme ---> ', this.theme)

      if (this.theme !== 'light') {
        localStorage.setItem("iframe-order-theme", 'dark');
        document.documentElement.setAttribute("data-theme", "iframe-order-public-dark");
      }
      else {
        localStorage.setItem("iframe-order-theme", 'dark');
        document.documentElement.setAttribute("data-theme", "iframe-order-public-light")
      }

      if (lang.length > 0 && lang.length < 3) {
        this.lang = lang;
        i18n.locale = this.lang;
      } else {
        this.lang = 'en';
        i18n.locale = 'en';
      }

      if(apiKey) {
        this.apiKey = apiKey.replaceAll(' ', '+');
      }

      if(orderId.length > 0 && orderId.length <= 100) {
        this.orderId = orderId;
      }
      if(description.length > 0 && description.length <= 255) {
        this.description = description;
      } else {
        this.description = '';
      }
      if(currency.length > 0 && currency.length <= 10) {
        this.currency = currency;
      } else {
        this.currency = 'USDT';
      }
      if(network.length > 0 && network.length <= 20) {
        this.network = network;
      } else {
        this.network = 'ethereum';
      }
      if(amount.length > 0 && amount.length <= 20 && isFinite(amount)) {
        this.amount = amount;
      } else {
        this.amount = '0';
      }
      if(payerEmail.length) {
        this.clientEmail = payerEmail;
      }
    },
    buildQuery() {
      const query = new URLSearchParams();

      // обратно в урл пихать апи ключ не будем
      if(this.orderId) query.set('orderId', this.orderId);
      if(this.description) query.set('description', this.description);
      if(this.currency) query.set('currency', this.currency);
      if(this.network) query.set('network', this.network);
      if(this.amount) query.set('amount', this.amount);
      if(this.clientEmail) query.set('email', this.clientEmail);

      return query;
    },
    replaceUrl() {
      const query = this.buildQuery();

      query.set('theme', this.theme);
      query.set('lang', this.lang);

      window.history.replaceState( {} , 'ocp', (window.location.pathname.split('?')[0]) + '?' + query.toString() );
    },

    async choosePaymentMethod(method) {
      this.paymentMethod = method;

      if(this.pending) {
        return;
      }

      this.pending = true;

      try {
        const res = await axios.post('/public-api/i-order/' + this.organizationId, {
          apiKey: this.apiKey,
          orderId: this.orderId,
          description: this.description || null,
          currency: this.currency,
          network: this.network,
          amount: this.amount,
          payerEmail: this.clientEmail,
        });

        if(!res.data.success) {
          this.error = res.data.error;
        } else {
          this.orderInfo = res.data.result;
          this.mergeOrphans()

          this.monitorOrder()
              .catch();
        }
      } catch (e) {
        this.error = 'Internal server error. Please retry later';
      }

      this.pending = false;
    },

    async loadBaseInfo() {
      if(!this.isOrganizationDefined || !this.isOrderDefined) {
        return;
      }

      if(this.pending) {
        return;
      }

      this.pending = true;

      try {
        const res = await axios.get('/public-api/i-order/' + this.organizationId + '?' + this.buildQuery().toString());

        if(!res.data.success) {
          this.error = res.data.error;

          this.pending = true;

          return;
        } else {
          this.baseInfo = res.data.result;

          // console.log('this.baseInfo ---> ', this.baseInfo)
        }
      } catch (e) {
        this.error = 'Internal server error. Please retry later';

        this.pending = false;

        return;
      }

      try {
        const res = await axios.get('/public-api/i-order/' + this.organizationId + '/' + this.orderId);

        if(!res.data.success) {
          this.error = res.data.error;
        } else {
          if(res.data.result) {
            this.paymentMethod = 'qr';
            this.paymentEmail = 'filled';
            this.orderInfo = res.data.result;

            this.mergeOrphans()

            this.monitorOrder()
              .catch();
          }
        }
      } catch (e) {
        this.error = 'Internal server error. Please retry later';
      }

      this.pending = false;
    },

    async monitorOrder() {
      while(true) {
        await new Promise(r => setTimeout(r, 5000));

        try {
          const res = await axios.get('/public-api/i-order/order/' + this.orderInfo.id);

          if (res.data.success) {
            this.orderInfo = res.data.result;

            this.mergeOrphans()
          }

          if(['init', 'pending'].includes(this.orderInfo.status)) {
            continue;
          }

          break;
        } catch (e) {}
      }
    },
    mergeOrphans() {
      let { orphans, transactions } = this.orderInfo

      if (!orphans) return

      orphans.forEach(orphan => {
        orphan.isOrphan = true
      })


      this.orderInfo.transactions = [...transactions, ...orphans]
      this.orderInfo.transactions = this.orderInfo.transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    },
    onSubmit() {
      this.paymentEmail = this.clientEmail;

      this.choosePaymentMethod('qr');
    },
  },
}
</script>

<style lang="scss">
  .iframe-order-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 13px;

    font-family: Gilroy;

    box-shadow: 0 0 25px 10px #000;

    width: 390px;
    height: 585px;
    border-radius: 10px;

    padding: 26px 26px 0;

    background-color: var(--primary-background-color);
    overflow: hidden;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .wrong-statuses {
      color: var(--primary-text-color);
      text-align: center;
    }
  }

  .email-form {
    height: 100%;
  }
  .error {
    color: var(--primary-text-color);
  }
</style>
